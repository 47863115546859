/**
 * Attention!
 *
 * Please use [SVG OMG](https://jakearchibald.github.io/svgomg/)
 * when importing SVGs. Make sure you configure it to remove
 * explicit width/height attributes (prefer viewBox to w/h) and
 * prettify the markup. Don't forget to crank up "precision"
 * to 100%. Thank you
 */
import React, { ReactElement } from "react"

import AddIcon from "../assets/icons/add.svg"
import AttachFileIcon from "../assets/icons/attach_file.svg"
import DoneIcon from "../assets/icons/done.svg"
import MusicNoteIcon from "../assets/icons/music_note.svg"
import SearchIcon from "../assets/icons/search.svg"

export type Icons = "add" | "attach_file" | "done" | "music_note" | "search"

type Size = 6 | 8

export function Icon({
  name,
  className = "",
  size = 6,
}: {
  name: Icons
  className?: string
  size?: Size
}): ReactElement {
  className = className + " fill-current " + sizeStyles(size)

  switch (name) {
    case "add":
      return <AddIcon className={className} />
    case "attach_file":
      return <AttachFileIcon className={className} />
    case "done":
      return <DoneIcon className={className} />
    case "music_note":
      return <MusicNoteIcon className={className} />
    case "search":
      return <SearchIcon className={className} />
  }
}

function sizeStyles(s: Size): string {
  switch (s) {
    case 8:
      return "w-8 h-8"
    case 6:
      return "w-6 h-6"
  }
}
