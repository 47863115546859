import { useStaticQuery, graphql } from "gatsby"

type Images =
  | "konkatedralaImage"
  | "notFoundImage"
  | "organPresov"
  | "logoFarnost"
  | "logoPsk"
  | "logoPskOld"
  | "logoPresovMesto"
  | "logoMinisterstvoKultury"
  | "project"

export const useImage = (): any => {
  const myImages = useStaticQuery(
    graphql`
      query MyImages {
        konkatedralaImage: file(relativePath: { eq: "konkatedrala.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        notFoundImage: file(relativePath: { eq: "notFound.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        organPresov: file(relativePath: { eq: "organ-presov.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoFarnost: file(
          relativePath: { eq: "logos/farnost-sv-mikulasa-presov-logo.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoPsk: file(
          relativePath: { eq: "logos/presovsky-samospravny-kraj.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoPskOld: file(relativePath: { eq: "logos/PSK-logo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoPresovMesto: file(
          relativePath: { eq: "logos/presov-mesto-logo.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoMinisterstvoKultury: file(
          relativePath: { eq: "logos/ministerstvo-kultury-SR-logo.jpg" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        project: file(relativePath: { eq: "project.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return myImages
  //   return myImages[name].childImageSharp
}
