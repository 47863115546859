import React, { ReactElement, FC } from "react"
// import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Img from "gatsby-image"
import { Icon } from "../components/Icon"
import { useImage } from "../hooks/useImage"
const IndexPage: FC = () => {
  const title =
    "Rekonštrukcia a reštaurovanie organu v konkatedrále sv. Mikuláša Prešov"
  return (
    <Layout>
      <Seo title={title} />
      <h1 className="pb-2 text-3xl font-bold">{title}</h1>
      <Content />
    </Layout>
  )
}

const Content: FC = () => {
  const { organPresov, logoPsk } = useImage()

  const title =
    "Rekonštrukcia a reštaurovanie organu v konkatedrále sv. Mikuláša Prešov"
  return (
    <div>
      <div className="flex flex-col md:flex-row-reverse bg-gray-300 rounded p-4">
        <div className="flex-shrink-0 m-2">
          <Img
            fluid={organPresov.childImageSharp.fluid}
            className="md:w-64 rounded-lg"
          />
        </div>
        <div className="m-2">
          <p className="mb-2">
            Rímskokatolícka farnosť sv. Mikuláša, Prešov už dlhé roky postupne
            realizuje obnovu a reštaurovanie vzácnych národných kultúrnych
            pamiatok, ktoré má vo svojom vlastníctve. Takto obnovené kultúrne
            pamiatky prispievajú k zvýšeniu návštevnosti prešovského regiónu
            domácimi a zahraničnými turistami, čo súčasne prispieva aj k rozvoju
            ekonomiky a zamestnanosti v Prešovskom samosprávnom kraji.
          </p>
          <p className="mb-2 font-bold">
            Päťdielny renesančno-rannobarokový organ a parapet empory je
            významnou národnou kultúrnou pamiatkou pochádzajúcou zo 17.storočia.
            Pozostáva zo 43 registrov umiestnených v 3 skriniach. Má 3480 píšťal
            a 20 zvonov. Posledná oprava vnútorných častí nástroja bola v roku
            1960. Organ patrí medzi najvzácnejšie organy na Slovensku. Slúži
            nielen pre cirkevné účely, ale aj širokej verejnosti a obdivujú ho
            aj turisti.
          </p>
          <p className="">
            Farnosť uskutočňuje benefičné koncerty na pomoc rodinám v núdzi a
            pre milovníkov organovej hudby. Pomáha mladým talentovaným umelcom -
            organistom - v ich príprave a vystúpeniach. Aby sa opravili
            jednotlivé poškodenia, zachoval sa dobrý technický stav drevených
            častí pamiatky, naďalej mohla slúžiť verejnosti a bola uchovaná ako
            jedinečná kultúrna pamiatka, je naliehavou potrebou uskutočniť aj
            rozsiahlu, finančne náročnú komplexnú rekonštrukciu trojmanuálového
            píšťalového organa s pedalom, inštalovaného do historickej organovej
            skrine. Finančná podpora PSK prostredníctvom dotácie významne pomôže
            pri zreštaurovaní a zachovaní tejto národnej kultúrnej pamiatky
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center py-8">
        <div className="text-xl border border-gray-500 bg-gray-100 rounded p-4 mb-4">
          <p>
            <span className="font-bold">Názov projektu</span>
            <br /> Rekonštrukcia a reštaurovanie organu v konkatedrále sv.
            Mikuláša Prešov
          </p>{" "}
          <p className="pt-4">
            <span className="font-bold">Prijímateľ podpory</span>
            <br /> Rímskokatolícka farnosť sv. Mikuláša, Prešov
          </p>
          <p className="pt-4">
            <span className="font-bold">Termín realizácie projektu</span>
            <br /> 1.január 2019 - 31.október 2021
          </p>
        </div>
        <table className="table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Popis</th>
              <th className="px-4 py-2">Suma v &euro;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">Výška schválenej dotácie</td>
              <td className="border px-4 py-2">200 000</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="border px-4 py-2">Spolufinancovanie *</td>
              <td className="border px-4 py-2">220 240</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">Celkový rozpočet</td>
              <td className="border px-4 py-2">420 240</td>
            </tr>
          </tbody>
        </table>
        <p className="mt-4">
          * - Minimálne spolufinancovanie je 50% z požadovanej dotácie.
        </p>
      </div>

      <div className="flex flex-col items-center py-8">
        <p className="font-bold">
          Projekt je podporený z rozpočtu Prešovského samosprávneho kraja.
        </p>

        <Img
          fluid={logoPsk.childImageSharp.fluid}
          alt="Prešovský samosprávny kraj"
          className="md:w-64"
        />
      </div>

      <div className="flex flex-col items-center justify-center mt-4 p-8 bg-gray-300 rounded">
        <p className="text-center">
          Číslo zmluvy o poskytnutí dotácie: Zmluva č. 944/2019/ORR
        </p>

        <a
          href="https://po-kraj.sk/files/zmluvy/10185_2019-09-04_zmluva_944-2019-orr_vyzva-pre-region-pre-rok-2019.pdf"
          className="inline-block mt-4 p-4 bg-blue-700 text-gray-100 rounded"
        >
          <Icon name="attach_file" className="inline" />
          <span>Stiahnuť zmluvu</span>
        </a>
      </div>
      <p></p>
      <p></p>
    </div>
  )
}

export default IndexPage
